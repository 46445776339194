<template>
    <main id="login-page">
        <div class="container login-page">
            <div class="row">
                <div class="col-sm-12 logo">
                       <a href="/" title="sellio">   
                           <img src="img/logo.svg" class="img-fluid" alt="Logo" />
                       </a>
                </div>
                <div class="col-sm-12"> 
                    <form @submit.prevent="handleSubmit">
                        <div class="form-group">
                            <label for="email">דואר אלקטרוני</label>
                            <input type="text" v-model="email" name="email" class="form-control email" placeholder="דואר אלקטרוני" id="email" :class="{ 'is-invalid': submitted && !email }" autocomplete="off" />
                            <div v-show="submitted && !email" class="invalid-feedback">זהו שדה נדרש</div>
                        </div>
                        <div class="form-group">
                            <label htmlFor="password">סיסמה</label>
                            <input type="password" v-model="password" name="password" class="form-control" :class="{ 'is-invalid': submitted && !password }"  placeholder="סיסמה" id="pwd"  autocomplete="off"/>
                            <div v-show="submitted && !password" class="invalid-feedback">זהו שדה נדרש</div>
                        </div>
                        <div class="form-group">
                            <button class="btn btn-primary w-100">כניסה למערכת</button>
                            <img v-if="requestStatus" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
    data () {
        return {email: '', password: '', submitted: false }
    },
    computed: mapState({ 
        user: state => state.user.userToken,
        requestStatus: state => state.user.status,
    }),
    created () {
    },
    methods: {
        ...mapActions('user', ['login']),
        handleSubmit () {
            this.submitted = true; 
            const { email, password } = this;
            if (email && password) {
                this.$store.dispatch('user/login',{ email, password })
            }
        }
    }
};
</script>
<style>

</style>